@tailwind base;

h1 {
  @apply text-4xl;
}

h2 {
  @apply text-2xl;
}

h3 {
  @apply text-xl;
}

@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bricolage-grotesque-bold {
  font-family: "Bricolage Grotesque", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

.kaushan-script-regular {
  font-family: "Kaushan Script", serif;
  font-weight: 400;
  font-style: normal;
}

.montserrat-regular {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.montserrat-regular {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.montserrat-bold {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.montserrat-regular-italic {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.montserrat-bold-italic {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-style: italic;
}

@layer base {
  @font-face {
    font-family: "GT-America-Mono-Regular-Trial";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/src/assets/font/GT-America/GT-America-Standard-Regular-Trial.otf) format("opentype");
  }
}

.secondary-btn-shadow {
  box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.1);
}

@layer components {
  .transition-custom {
    @apply transition-all duration-[300ms];
  }
}

.swiper-pagination-progressbar {
  background: #876ebc !important;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #ffffff !important;
}

.custom-gradient {
  background: linear-gradient(to top, #301645, #301645 0%, transparent);
}

@layer utilities {
  .custom-gradient2 {
    background: linear-gradient(to top, #8d19da, #f20b97);
  }

  .custom-gradient3 {
    background: linear-gradient(to top, #992727, #ff5d5d);
  }
}

@keyframes pos-spin1 {
  to {
    transform: rotate(360deg);
  }
}

.positive-animate-spin1 {
  animation: pos-spin1 8s linear infinite;
}

@keyframes -spin1 {
  to {
    transform: rotate(-360deg);
  }
}

.-animate-spin1 {
  animation: -spin1 8s linear infinite;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeIn1 {
  animation: fade 1s linear forwards;
}

.fadeIn2 {
  animation: fade 1s linear 1s forwards;
}

.fadeIn3 {
  animation: fade 1s linear 2s forwards;
}

.icon-shadow {
  box-shadow:
    rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
    rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

@keyframes popup {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

.popup {
  animation: popup 1s ease-in-out infinite alternate;
}
